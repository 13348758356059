import React, {createContext, useContext, useState, useEffect} from 'react'
import {supabaseClient} from "../config/supabase-client" //type
import { User } from '@supabase/supabase-js';
import data2 from "../shared/data/data2.json" 
import { useToast } from './toasterProvider';
import { checkAdmin } from '../API/authAPI';
type AuthContextParameter = {
  user: User | null | undefined, 
  session: any,
  logged : boolean,
  signOut: () => void, 
  signIn: (data:Login) => Promise<signInMessage>, 
  signInMagical: (data:any) => void,
  admin : boolean,
}
type Login = {
  email:string;
  password:string;
}

type signInMessage = {
  message: string,
  errorLogin: boolean
};

//Need to define type
export const AuthContext = createContext<AuthContextParameter>
  ({
    logged:false,
    signIn: async (data: Login): Promise<signInMessage>=> {
      return {message:"", errorLogin:false}
    }, 
    user: null,
    session: null, 
    signOut:()=>{},
    signInMagical: () => {},
    admin: false
  });

export default function AuthProvider({children}:any) {
  const toast = useToast()
//provider returning user data, listenning changing state user
  const [user, setUser] = useState<User | null>()
  const [session, setLocalSession] = useState<any>()
  const [loading, setLoading] = useState<boolean>(true)
  const [admin, setAdmin] = useState(false)
  
  //A mettre dans .env
  const ERROR_MESSAGES = {
    generic: "Il y a eu une erreur côté serveur",
    signIn: "Il y a eu une erreur",
    success: "Succés"
  };
  //catch if there user or session actif
  function isLogged(){
    return user || session
  }

  const value = {
    signUp: (data:any) => supabaseClient.auth.signUp(data),
    signIn: (data:any) => signInAsync(data),
    signOut: () => signOut(),
    signInMagical: (data:any) => sign_in_magical(data),
    logged: isLogged(),
    user,
    session,
    admin,
  }
  
 
  function sign_in_magical(password:string){
    console.log("Début de l'enregistrement avec le mot de passe magique")
    if (password===data2.mdp){
      const data = { id : "randomID" };
      localStorage.setItem('sessionData', JSON.stringify(data));
      setLocalSession(data);
      console.log(localStorage.getItem('sessionData'))
      console.log("Fin de l'enregistrement avec le mot de passe magique")
    } else{
      console.log("errur dans l'identifiant")
    }
   
  } 
  async function signInAsync(dataLogin: Login): Promise<signInMessage> {
    try {
      const { data, error } = await supabaseClient.auth.signInWithPassword(dataLogin);
      if (error) {
        return { message: `${ERROR_MESSAGES.signIn} ${error}`, errorLogin: true };
      }
      const { data: { user } } = await supabaseClient.auth.getUser();
      setUser(user);
      return { message:`${ERROR_MESSAGES.success}, vous êtes connecté!`, errorLogin: false };
    } catch (err) {
      return { message: `${ERROR_MESSAGES.generic} ${err}`, errorLogin: true };
    }
  }
  
  function signOut(){
    supabaseClient.auth.signOut()
    // IT WILL DELETE ALL THE SESSION; WATCHOUT!
    setLocalSession(null) 
  }

  function sign_out_magical(password:string){
    console.log("Début de l'enregistrement avec le mot de passe magique")
    if (password===data2.mdp){
      setLocalSession(null);
      console.log("Fin de l'enregistrement avec le mot de passe magique")
    } else{
      console.log("errur dans l'identifiant")
    }
  } 

  useEffect(()=>{
    //set user 
    const fetchData = async () => {
      try {
          const { data: { user }, error } = await supabaseClient.auth.getUser();
          if (error) {
              setLoading(false);
              console.error("Error retrieving user:", error);
              return;
          }
          if (user) {
              setUser(user);
              const isAdmin = await checkAdmin(user);
              setAdmin(isAdmin);
              setLoading(false);

          } else {
              setLoading(false);
              console.error("No user found.");
          }
      } catch (err) {
          console.error("Server error:", err);
          setLoading(false);
      }
  };

  fetchData();

    //lisetnning to signout event
    const { data } = supabaseClient.auth.onAuthStateChange(
        (event) => {
          if (event === 'SIGNED_OUT') {
            console.log("SIGN OUT!")
            setUser(null)
          }
        })
    // call unsubscribe to remove the callback
    return () => {
        data.subscription.unsubscribe()
        }
  },[])

  //need to retrun error to catch error and display it
  //access all function to auth-
 

  return (
    <AuthContext.Provider value = {value}> 
        {!loading? children:"Le site est pour l'instant inaccessible"}
    </AuthContext.Provider>
  )
}

//export hook that can be use and rerender page when use
export const useAuth = () => {
  return useContext(AuthContext);
};