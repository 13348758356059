/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import mainTheme from '../shared/theme'
import { jsx,css } from '@emotion/react'
import { Link, useNavigate} from 'react-router-dom'
import { useAuth } from '../hook/authProvider'

export default function Header() {
  return(
    <div>
      <LoginStatus/>
      <Head/>
      <Navgation/>
    </div>
  )
}

export function LoginStatus(){
  const style = css`
    font-size:20px;
    background-color: ${mainTheme.color.bgColor};
    padding: 10px;
    text-align:right;
    button {
      font-size:20px;
      margin-left:20px;
    }
    `
    const navigate = useNavigate()
    const {admin, signOut, logged} = useAuth()
    
  return(
    <div css ={style}> 
      {logged?
        <div >
          <button onClick={signOut}>Se déconnecter</button>
        </div>
        :
        <button onClick={()=>{navigate("/login")}}>S'enregistrer</button>
        }
    </div>
  )
}

function Head(){
    const style = css`
      padding: 1em;
      display: flex;
      flex-direction: column; 
      align-items: center;
      font-size: ${mainTheme.fontSizes.large};
      font-weight: 800
  `
    return (
    <div css = {style}>SAUV MES FEDS</div>
    )
}

function Navgation(){
  const {admin} = useAuth()
  console.log("isAdmin?", admin)
  const paragraph = css`
 
  display: flex; 
  gap: 20px;
  justify-content: center;
  font-size:1.2em;
  font-weight: 700;
  margin-bottom:15px;
  a { 
    cursor: pointer;
    text-decoration: none;
    color: turquoise;
    &:before, &:after {
      content: "";
      display: block;
      border-top: 2px solid currentColor;
      transition: all 0.3s;
    };
    &:hover:before {
      opacity: 1;
      transform: translateY(-7px);
    };
    &:hover:after {
      opacity: 1;
      transform: translateY(7px);
    }
  }
`
  return (
  <div css={paragraph}>
    <Link to = {'/'}>ACCEUIL</Link>
    <Link to = {'QCM/'}>QCM</Link>
   {admin?<Link to = {'debug/'}>Debug</Link>:"" } 
  </div>
  )

}
